import React, { Fragment, useEffect, useState } from 'react'
import { Button, Chip, CircularProgress, Container, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ChevronLeft, Close, Home, ImageSearch, Search, WhatsApp } from '@mui/icons-material'
import AppBar from '../AppBar'

const API_URL = 'https://api.sogevo.com/V1.0.0'
const BASE_URL = 'https://api.sogevo.com'

// const API_URL = 'http://192.168.1.170:5000/V1.0.0'
// const BASE_URL = 'http://192.168.1.170:5000'

export default function Car() {
    const [cars, setCars] = useState([])
    const [isFetched, setIsFetched] = useState(false)
    const [currentphoto, setCurrentphoto] = useState('')

    const [searchQ, setSearchQ] = useState('')
    const [resultSearch, setResultSearch] = useState([])
    const [openSearchResult, setOpenSearchResult] = useState(false)
    const [isSearching, setIsSearching] = useState(false)

    const [adminSettings, setAdminSettings] = useState(null)

    const params = useParams()
    const navigate = useNavigate()

    async function fetchCars() {

        await axios({
            method: "get",
            url: `${API_URL}/owners/cars/${params.id}`,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setCars([response.data.data[0].car])
                        setIsFetched(true)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const handleSearchCars = async (searchQ) => {
        setIsSearching(true)

        await axios({
            method: "post",
            url: `${API_URL}/owners/cars/shop-or-location/list/search`,
            data: { 'searchQ': searchQ },
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setResultSearch(response.data.data)
                        setIsSearching(false)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const fetchSettings = async () => {
        await axios({
            method: "get",
            url: `${API_URL}/settings`,
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        const mAdminSettings = {}
                        response.data.data.forEach(element => {
                            if (element.wording === "phone-number") {
                                mAdminSettings.phoneNumber = element.value
                            } else if (element.wording === "whatsapp") {
                                mAdminSettings.whatsapp = element.value
                            }
                        })

                        setAdminSettings(mAdminSettings)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchCars()
        fetchSettings()
    }, [])

    return (
        <Fragment>
            <AppBar />
            <div className='top-tiny-bar'>
                <Container>
                    <Grid container>
                        <Grid item xl={8} lg={7} md={6} sm={4} xs={4}>
                            <Link to='/'><strong><Home sx={{ mt: "20px", position: 'absolute', marginRight: 10 }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accueil</strong></Link>
                        </Grid>
                        <Grid item xl={4} lg={5} md={6} sm={8} xs={8} textAlign={`right`}>
                            <div className='search-form'>
                                <table width={`100%`}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input type="search" placeholder='Rechercher ...' value={searchQ} onFocus={() => {
                                                    setOpenSearchResult(true)
                                                }} onChange={(e) => {
                                                    if (e.target.value.trim().length > 0 || e.target.value.trim() === '') {
                                                        setSearchQ(e.target.value)
                                                        if (e.target.value.trim().length >= 3) {
                                                            handleSearchCars(e.target.value)
                                                        }
                                                    }
                                                }} />
                                            </td>
                                            <td width={20}>
                                                <button><Search /></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {
                openSearchResult &&
                <div className='search-result'>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography pl={2} pt={2}>
                                <strong>Rechercher une voiture</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography textAlign={`right`} pr={1} pt={1}>
                                <IconButton onClick={() => {
                                    setOpenSearchResult(false)
                                }}>
                                    <Close />
                                </IconButton>
                            </Typography>
                        </Grid>
                    </Grid>

                    {
                        isSearching ? <Typography textAlign={`center`}><CircularProgress size={20} /></Typography> :
                            <Fragment>
                                {
                                    resultSearch.length > 0 &&
                                    <List>
                                        {
                                            resultSearch.map((car, index) => {
                                                return (
                                                    <ListItem disablePadding key={index}>
                                                        <ListItemButton onClick={() => {
                                                            setOpenSearchResult(false)
                                                            navigate('/car/' + car.id)
                                                        }}>
                                                            <ListItemText primary={`${car.brand} ${car.model} ${car.year}`} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                }
                            </Fragment>
                    }
                </div>
            }
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography textAlign={`center`}>

                        </Typography><br /><br />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className='sm-hide'>
                            <IconButton aria-label="Back" onClick={() => {
                                navigate(-1)
                            }}>
                                <ChevronLeft />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid><br /><br />
                <Grid container spacing={3}>
                    {
                        !isFetched ?
                            <Grid item xs={12}><Typography component={'div'} textAlign={'center'}><CircularProgress /></Typography></Grid> :
                            cars.map((car, index) => {
                                return (
                                    <Grid container key={index}>
                                        <Grid item xl={2} lg={1} md={12} sm={12} xs={12}></Grid>
                                        <Grid item xl={8} lg={10} md={12} sm={12} xs={12} textAlign={'center'}>

                                            {
                                                car.carPhotos[0] ?
                                                    <Fragment>

                                                        <Grid container>
                                                            <Grid item xl={3} lg={2} md={1} sm={12} xs={12}></Grid>
                                                            <Grid item xl={6} lg={8} md={10} sm={12} xs={12}>
                                                                <img src={currentphoto === '' ? `${BASE_URL}/${car.carPhotos[0].carPhoto}` : currentphoto} alt="Car photo" width='100%' /><br /><br />
                                                            </Grid>
                                                            <Grid item xl={3} lg={2} md={1} sm={12} xs={12}></Grid>
                                                        </Grid>

                                                        <Grid container>

                                                            {
                                                                car.carPhotos.length === 0 ?
                                                                    <Fragment>
                                                                        <Grid border={'2px solid #CCC'} item xs={4} minHeight={170}>
                                                                            <ImageSearch sx={{ mt: 7, fontSize: 50 }} />
                                                                        </Grid>
                                                                        <Grid border={'2px solid #CCC'} item xs={4} minHeight={170}>
                                                                            <ImageSearch sx={{ mt: 7, fontSize: 50 }} />
                                                                        </Grid>
                                                                        <Grid border={'2px solid #CCC'} item xs={4} minHeight={170}>
                                                                            <ImageSearch sx={{ mt: 7, fontSize: 50 }} />
                                                                        </Grid>
                                                                    </Fragment> :
                                                                    car.carPhotos.map((carPhoto, index) => {
                                                                        return (
                                                                            <Grid item xs={4} key={index} overflow={'hidden'}
                                                                                style={{
                                                                                    backgroundImage: `url(${BASE_URL}/${carPhoto.carPhoto})`,
                                                                                    backgroundPosition: 'center',
                                                                                    backgroundSize: 'cover',
                                                                                    border: '2px solid #CCC',
                                                                                    height: 170
                                                                                }}
                                                                                onClick={() => {
                                                                                    setCurrentphoto(`${BASE_URL}/${carPhoto.carPhoto}`)
                                                                                }}
                                                                            >
                                                                            </Grid>
                                                                        )
                                                                    })
                                                            }

                                                            {
                                                                (car.carPhotos.length < 3 && car.carPhotos.length === 1) ?
                                                                    <Fragment>
                                                                        <Grid border={'2px solid #CCC'} item xs={4} minHeight={170}>
                                                                            <ImageSearch sx={{ mt: 7, fontSize: 50 }} />
                                                                        </Grid>
                                                                        <Grid border={'2px solid #CCC'} item xs={4} minHeight={170}>
                                                                            <ImageSearch sx={{ mt: 7, fontSize: 50 }} />
                                                                        </Grid>
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        {
                                                                            car.carPhotos.length === 2 && <Grid border={'2px solid #CCC'} item xs={4} minHeight={170}>
                                                                                <ImageSearch sx={{ mt: 7, fontSize: 50 }} />
                                                                            </Grid>
                                                                        }
                                                                    </Fragment>
                                                            }
                                                        </Grid><br /><br />
                                                    </Fragment> :
                                                    <Fragment>
                                                        <div style={{ height: 220, backgroundColor: '#DDD' }}>
                                                            <ImageSearch sx={{ mt: 10, fontSize: 60 }} />
                                                        </div><br /><br />
                                                    </Fragment>
                                            }


                                            {
                                                (car.isOnLocation && car.isOnShop) ?
                                                    <Chip label={<strong>Location & vente</strong>} color="success" /> :
                                                    car.isOnLocation ? <Chip label={<strong>En location</strong>} color="primary" /> :
                                                        car.isOnShop ? <Chip label={<strong>En vente</strong>} color="warning" /> : ''
                                            }

                                            <Typography gutterBottom variant="h6" component="div" mt={1}>
                                                <strong>{car.brand} {car.model} {car.year}</strong>
                                            </Typography>
                                            <Typography variant="body2" component='div'>
                                                <strong>Couleur</strong> : {car.color}<br />
                                                <strong>Prix</strong> :&nbsp;
                                                {
                                                    (car.isOnLocation && car.isOnShop) ?
                                                        <strong style={{ color: 'green' }}>{car.locationFees} F CFA - {car.shopFees} F CFA</strong> :
                                                        car.isOnLocation ? <strong style={{ color: 'green' }}>{car.locationFees} F CFA</strong> :
                                                            car.isOnShop ? <strong style={{ color: 'green' }}>{car.shopFees} F CFA</strong> : ''
                                                }
                                                <br /><br /><br />

                                                {
                                                    car.comment !== '' &&
                                                    <Fragment>
                                                        <strong>DESCRIPTION</strong><br />
                                                        {car.comment === '' && "Pas de commentaire"}
                                                        {car.comment}

                                                    </Fragment>
                                                }

                                                <br /><br />
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <Button fullWidth variant='outlined' sx={{ borderRadius: 15 }} onClick={() => {
                                                            let textToSend = `sms:${adminSettings ? adminSettings.phoneNumber : '22891019245'}&body=`
                                                            if (car.isOnLocation && car.isOnShop) {
                                                                textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais avoir plus d\'informations '
                                                            } else if (car.isOnLocation) {
                                                                textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais le louer '
                                                            } else if (car.isOnShop) {
                                                                textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais l\'acheter '
                                                            }

                                                            textToSend += "https://shop.sogevo.com/car/" + car.id

                                                            window.location = textToSend
                                                        }}>
                                                            <strong>Message</strong>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button fullWidth variant='outlined' color='warning' sx={{ borderRadius: 15 }} onClick={() => {
                                                            window.location = `tel:${adminSettings ? adminSettings.phoneNumber : '22891019245'}`
                                                        }}>
                                                            <strong>Appel</strong>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button fullWidth variant='outlined' color='success' sx={{ borderRadius: 15 }} onClick={() => {
                                                            let textToSend = `https://wa.me/${adminSettings ? adminSettings.whatsapp : '22891019245'}?text=`
                                                            if (car.isOnLocation && car.isOnShop) {
                                                                textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais avoir plus d\'informations '
                                                            } else if (car.isOnLocation) {
                                                                textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais le louer '
                                                            } else if (car.isOnShop) {
                                                                textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais l\'acheter '
                                                            }

                                                            textToSend += "https://shop.sogevo.com/car/" + car.id

                                                            window.location = textToSend
                                                        }}>
                                                            <strong>WhatsApp</strong>
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                            </Typography><br /><br />
                                            <br /><br />
                                        </Grid>
                                        <Grid item xl={2} lg={1} md={12}></Grid>
                                    </Grid>
                                )
                            })
                    }
                </Grid>
            </Container>
        </Fragment>
    )
}