import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, CircularProgress, Container, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Modal, Typography } from '@mui/material'
import { AttachMoney, CarRental, Close, History, ImageSearch, ListAlt, Search } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import axios from 'axios'
import AppBar from '../AppBar'
import banner01 from '../../assets/images/p1.png'
import banner02 from '../../assets/images/p3.png'
import banner03 from '../../assets/images/p2.png'
import Slider from 'react-slick'

const API_URL = 'https://api.sogevo.com/V1.0.0'
const BASE_URL = 'https://api.sogevo.com'

// const API_URL = 'http://192.168.1.170:5000/V1.0.0'
// const BASE_URL = 'http://192.168.1.170:5000'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
}

const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", backgroundColor: "tranparent", borderRadius: '50%', padding: '3px 1px 0 1px', marginRight: "60px", marginTop: -20}}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", backgroundColor: "tranparent", borderRadius: '50%', padding: '3px 1px 0 1px', marginLeft: "30px", marginTop: -20, zIndex: 2}}
            onClick={onClick}
        />
    )
}

export default function Home() {
    const [searchQ, setSearchQ] = useState('')
    const [resultSearch, setResultSearch] = useState([])
    const [openSearchResult, setOpenSearchResult] = useState(false)
    const [isSearching, setIsSearching] = useState(false)

    const [cars, setCars] = useState([])
    const [isFetched, setIsFetched] = useState(false)
    const [openContact, setOpenContact] = useState(false)
    const [currentCar, setCurrentCar] = useState(null)

    const [adminSettings, setAdminSettings] = useState(null)

    const [target, setTarget] = useState('all')

    const navigate = useNavigate()

    async function fetchCars() {

        await axios({
            method: "get",
            url: `${API_URL}/owners/cars/shop-or-location/list/all`,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setCars(response.data.data)
                        setIsFetched(true)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    async function fetchLocationCars() {

        await axios({
            method: "get",
            url: `${API_URL}/owners/cars/shop-or-location/list/all`,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setCars(response.data.data.filter(filtredCar => filtredCar.isOnLocation === true))
                        setIsFetched(true)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    async function fetchShopCars() {

        await axios({
            method: "get",
            url: `${API_URL}/owners/cars/shop-or-location/list/all`,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setCars(response.data.data.filter(filtredCar => filtredCar.isOnShop === true))
                        setIsFetched(true)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    const handleSearchCars = async (searchQ) => {
        setIsSearching(true)

        await axios({
            method: "post",
            url: `${API_URL}/owners/cars/shop-or-location/list/search`,
            data: { 'searchQ': searchQ },
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setResultSearch(response.data.data)
                        setIsSearching(false)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const fetchSettings = async () => {
        await axios({
            method: "get",
            url: `${API_URL}/settings`,
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        const mAdminSettings = {}
                        response.data.data.forEach(element => {
                            if (element.wording === "phone-number") {
                                mAdminSettings.phoneNumber = element.value
                            } else if (element.wording === "whatsapp") {
                                mAdminSettings.whatsapp = element.value
                            }
                        })

                        setAdminSettings(mAdminSettings)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchCars()
        fetchSettings()
    }, [])

    return (
        <Fragment>

            <AppBar />

            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Slider {...settings} autoplay={true}>
                            <div>
                                <img src={banner01} alt="Image bannière" width={`100%`} />
                            </div>
                            <div>
                                <img src={banner02} alt="Image bannière" width={`100%`} />
                            </div>
                            <div>
                                <img src={banner03} alt="Image bannière" width={`100%`} />
                            </div>
                        </Slider>
                    </Grid>
                </Grid><br />

                <div className='search-form'>
                    <table width={`100%`}>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="search" placeholder='Rechercher ...' value={searchQ} onFocus={() => {
                                        setOpenSearchResult(true)
                                    }} onChange={(e) => {
                                        if (e.target.value.trim().length > 0 || e.target.value.trim() === '') {
                                            setSearchQ(e.target.value)
                                            if (e.target.value.trim().length >= 3) {
                                                handleSearchCars(e.target.value)
                                            }
                                        }
                                    }} />
                                </td>
                                <td width={20}>
                                    <button><Search /></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {
                    openSearchResult &&
                    <div className='search-result'>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography pl={2} pt={2}>
                                    <strong>Rechercher une voiture</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography textAlign={`right`} pr={1} pt={1}>
                                    <IconButton onClick={() => {
                                        setOpenSearchResult(false)
                                    }}>
                                        <Close />
                                    </IconButton>
                                </Typography>
                            </Grid>
                        </Grid>

                        {
                            isSearching ? <Typography textAlign={`center`}><CircularProgress size={20} /></Typography> :
                                <Fragment>
                                    {
                                        resultSearch.length > 0 &&
                                        <List>
                                            {
                                                resultSearch.map((car, index) => {
                                                    return (
                                                        <ListItem disablePadding key={index}>
                                                            <ListItemButton onClick={() => {
                                                                navigate('/car/' + car.id)
                                                            }}>
                                                                <ListItemText primary={`${car.brand} ${car.model} ${car.year}`} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    }
                                </Fragment>
                        }
                    </div>
                }

                <Grid container>
                    <Grid item xs={12}><br />
                        <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group">
                            <Button variant={target === 'all' ? 'contained' : 'outlined'} onClick={() => {
                                fetchCars()
                                setTarget('all')
                            }}><strong>Toutes les voitures</strong></Button>
                            <Button variant={target === 'location' ? 'contained' : 'outlined'} onClick={() => {
                                fetchLocationCars()
                                setTarget('location')
                            }}><strong>En location</strong></Button>
                            <Button variant={target === 'shop' ? 'contained' : 'outlined'} onClick={() => {
                                fetchShopCars()
                                setTarget('shop')
                            }}><strong>En vente</strong></Button>
                        </ButtonGroup><br /><br />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {
                        !isFetched ?
                            <Grid item xs={12}><Typography component={'div'} textAlign={'center'}><CircularProgress /></Typography></Grid> :
                            cars.map((car, index) => {
                                return (
                                    <Grid item xl={4} lg={3} md={6} xs={12} key={index}>
                                        <Card elevation={1} sx={{ borderRadius: 4 }}>
                                            <CardActionArea onClick={() => {
                                                navigate('/car/' + car.id)
                                            }}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Typography px={2} pt={1}>
                                                            <History fontSize='12' /> {car.updatedAt.substring(8, 10)}-{car.updatedAt.substring(5, 7)}-{car.updatedAt.substring(0, 4)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} textAlign={`right`}>
                                                        <Typography px={2} pt={1}>
                                                            {
                                                                (car.isOnLocation && car.isOnShop) ?
                                                                    <Fragment><CarRental style={{ color: 'blue' }} /><AttachMoney style={{ color: 'orange' }} /></Fragment> :
                                                                    car.isOnLocation ? <CarRental style={{ color: 'blue' }} /> :
                                                                        car.isOnShop ? <AttachMoney style={{ color: 'orange' }} /> : ''
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    car.carPhotos[0] ?
                                                        <CardMedia
                                                            component="img"
                                                            height="220"
                                                            image={`${BASE_URL}/${car.carPhotos[car.carPhotos?.length - 1]?.carPhoto}`}
                                                            alt="Photo voiture"
                                                        /> :
                                                        <div style={{ height: 220, backgroundColor: '#DDD', textAlign: 'center' }}><ImageSearch sx={{ mt: 10, fontSize: 60 }} /></div>
                                                }
                                                <CardContent>
                                                    {
                                                        (car.isOnLocation && car.isOnShop) ?
                                                            <Chip label={<strong>Location & vente</strong>} size='small' color="success" /> :
                                                            car.isOnLocation ? <Chip label={<strong>En location</strong>} color="primary" /> :
                                                                car.isOnShop ? <Chip label={<strong>En vente</strong>} color="warning" /> : ''
                                                    }

                                                    <Typography gutterBottom variant="h6" component="div" mt={1}>
                                                        <strong>{car.brand} {car.model} {car.year}</strong>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>Couleur</strong> : {car.color}<br />

                                                        {
                                                            (car.isOnLocation && car.isOnShop) ?
                                                                <Fragment><strong>Location</strong> : <strong style={{ color: 'green' }}>{car.locationFees} F CFA</strong> - <strong>Vente</strong> : <strong style={{ color: 'green' }}>{car.shopFees} F CFA</strong></Fragment> :
                                                                car.isOnLocation ? <strong style={{ color: 'green' }}>{car.locationFees} F CFA</strong> :
                                                                    car.isOnShop ? <strong style={{ color: 'green' }}>{car.shopFees} F CFA</strong> : ''
                                                        }
                                                        <br />
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Button fullWidth color="primary" style={{ backgroundColor: '#322975' }} variant='contained' disableElevation sx={{ borderRadius: 10 }} onClick={() => {
                                                    setCurrentCar(car)
                                                    setOpenContact(true)
                                                }}>
                                                    <strong>Contacter</strong>
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            })
                    }
                </Grid><br /><br />
            </Container>

            <Modal
                open={openContact}
                onClose={() => {
                    setOpenContact(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    {
                        currentCar &&
                        <Fragment>
                            {
                                currentCar.carPhotos[0] ?
                                    <img src={`${BASE_URL}/${currentCar.carPhotos[0].carPhoto}`} alt="Image voiture" width={`100%`} style={{ borderRadius: '20px' }} /> :
                                    <div style={{ height: 220, backgroundColor: '#DDD', textAlign: 'center' }}><ImageSearch sx={{ mt: 10, fontSize: 60 }} /></div>
                            }<br /><br />

                            {
                                (currentCar.isOnLocation && currentCar.isOnShop) ?
                                    <Chip label={<strong>Location & vente</strong>} size='small' color="success" /> :
                                    currentCar.isOnLocation ? <Chip label={<strong>En location</strong>} color="primary" /> :
                                        currentCar.isOnShop ? <Chip label={<strong>En vente</strong>} color="warning" /> : ''
                            }

                            <Typography gutterBottom variant="h6" component="div" mt={1}>
                                <strong>{currentCar.brand} {currentCar.model} {currentCar.year}</strong>
                            </Typography>
                            <Typography variant="body2">
                                <strong>Couleur</strong> : {currentCar.color}<br />

                                {
                                    (currentCar.isOnLocation && currentCar.isOnShop) ?
                                        <Fragment><strong>Location</strong> : <strong style={{ color: 'green' }}>{currentCar.locationFees} F CFA</strong> - <strong>Vente</strong> : <strong style={{ color: 'green' }}>{currentCar.shopFees} F CFA</strong></Fragment> :
                                        currentCar.isOnLocation ? <strong style={{ color: 'green' }}>{currentCar.locationFees} F CFA</strong> :
                                            currentCar.isOnShop ? <strong style={{ color: 'green' }}>{currentCar.shopFees} F CFA</strong> : ''
                                }
                                <br />
                            </Typography><br /><br />
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button fullWidth variant='outlined' sx={{ borderRadius: 15 }} onClick={() => {
                                        let textToSend = `sms:${adminSettings ? adminSettings.phoneNumber : '22891019245'}&body=`
                                        if (currentCar.isOnLocation && currentCar.isOnShop) {
                                            textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais avoir plus d\'informations '
                                        } else if (currentCar.isOnLocation) {
                                            textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais le louer '
                                        } else if (currentCar.isOnShop) {
                                            textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais l\'acheter '
                                        }

                                        textToSend += "https://shop.sogevo.com/car/" + currentCar.id

                                        window.location = textToSend
                                    }}>
                                        <strong>Message</strong>
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth variant='outlined' color='warning' sx={{ borderRadius: 15 }} onClick={() => {
                                        window.location = `tel:${adminSettings ? adminSettings.phoneNumber : '22891019245'}`
                                    }}>
                                        <strong>Appel</strong>
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth variant='outlined' color='success' sx={{ borderRadius: 15 }} onClick={() => {
                                        let textToSend = `https://wa.me/${adminSettings ? adminSettings.whatsapp : '22891019245'}?text=`
                                        if (currentCar.isOnLocation && currentCar.isOnShop) {
                                            textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais avoir plus d\'informations '
                                        } else if (currentCar.isOnLocation) {
                                            textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais le louer '
                                        } else if (currentCar.isOnShop) {
                                            textToSend += 'Bonjour, je suis intéressé(e) par votre véhicule et j\'aimerais l\'acheter '
                                        }

                                        textToSend += "https://shop.sogevo.com/car/" + currentCar.id

                                        window.location = textToSend
                                    }}>
                                        <strong>WhatsApp</strong>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fragment>
                    }
                </Box>
            </Modal>
        </Fragment>
    )
}